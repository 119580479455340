export const noGapInit = () => {
    if (document.querySelector('.footer')) {
        const sections = document.querySelectorAll('section');
        const bgSections = document.querySelectorAll('.js-bg');
        const footerSection = document.querySelector('.footer');

        bgSections.forEach(section => {
            if (section === sections[sections.length - 1]) {
                footerSection.style.marginTop= '0';
            }
        });
    }
}
