export const smoothScrollInit = () => {

const allButtons = document.querySelectorAll(".js-ancor-trigger");
allButtons.forEach((elem) => {
    elem.addEventListener("click", function (evt) {
        evt.preventDefault();
        const href = this.getAttribute("href");

        if (href) {
            const anchor = href.split("#")[1] ? href.split("#")[1] : "";
            const currentHrefRAW = window.location.href.split("#")[0];
            const currentHref = currentHrefRAW.endsWith("/")
                ? currentHrefRAW.slice(0, -1)
                : currentHrefRAW;
            console.log(href.split("#")[0], currentHref)
            if (href.split("#")[0] === currentHref) {
                $('html, body').animate({
                    scrollTop: $(`#${anchor}`).offset().top - 60
                }, 500);
            } else {
                window.location.href = href;
            }
        }
    });
});

function scrollToCenter(selector) {
    const el = $(selector);

    $("html, body").animate(
        {
            scrollTop: el.offset().top - 60,
        },
        400
    );
}

if (location.hash) {
    const scrollSection = document.querySelector(location.hash);
    if (scrollSection) {
        setTimeout(function () {
            window.scrollTo(0, 0);
        }, 1);

        setTimeout(() => {
            scrollToCenter(location.hash);
        }, 500);
    }
}

}