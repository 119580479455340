export const headerDropdownInit = () => {

if (document.querySelector('.js-dropdown')) {
    const btns = document.querySelectorAll('.js-dropdown');

    btns.forEach(btn => {
        let timer;

        btn.addEventListener('touchstart', (e) => {
            if (e.target.classList.contains('header__link--btn') || e.target.classList.contains('header__link-chevrone')) {
                e.preventDefault();

                btns.forEach(btnTrigger => {
                    if (btnTrigger !== btn) {
                        btnTrigger.classList.remove('open');
                    }
                });
                if (!btn.classList.contains('open')) {
                    btn.classList.add('open');
                } else {
                    btn.classList.remove('open');
                }
            }
        });

        btn.addEventListener('mouseenter', () => {
            btns.forEach(btnTrigger => {
                if (btnTrigger !== btn) {
                    btnTrigger.classList.remove('open');
                }
            });
            btn.classList.add('open');

            clearTimeout(timer);
        });

        btn.addEventListener('mouseleave', () => {
            timer = setTimeout(() => {
                btn.classList.remove('open');
            }, 1000);
        });
    });
}
}