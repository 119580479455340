import Swiper, {Autoplay, Navigation} from "swiper";

export const animalsInit = () => {
	if (document.querySelector(".animals__slider")) {
		const supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
		const cards = document.querySelectorAll('.animals__card');
		const animalSlider = new Swiper(".animals__slider", {
			modules: [Autoplay, Navigation],
			slidesPerView: 1,
			spaceBetween: 20,
			speed: 500,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			breakpoints: {
				568: {
					slidesPerView: 2,
				},
				1024: {
					slidesPerView: 3,
				},
			},
			navigation: {
				prevEl: '.animals .slider-btn.prev',
				nextEl: '.animals .slider-btn.next',
			}
		});
		function contentToggler(btn, content, btnSec, contentSec) {
			if (content.classList.contains('is-active')) {
				content.classList.remove('is-active');
				btn.classList.remove('is-active');
				animalSlider.autoplay.start();
			} else {
				btn.classList.add('is-active');
				content.classList.add('is-active');
				contentSec.classList.remove('is-active');
				btnSec.classList.remove('is-active');
				animalSlider.autoplay.stop();
			}
		}

		function contentClose(btn, content) {
			content.classList.remove('is-active');
			btn.classList.remove('is-active');
		}

		function initHandler(event, card, btnWeak, contentWeak, btnStr, contentStr) {
			card.addEventListener(event,  (e) => {
				e.stopPropagation();
			});
			document.addEventListener(event,  (e) => {
				if (!card.contains(e.target)) {
					contentClose(btnWeak, contentWeak);
					contentClose(btnStr, contentStr);
					animalSlider.autoplay.start();
				}
			});
			btnStr.addEventListener(event, () => contentToggler(btnStr, contentStr, btnWeak, contentWeak));
			btnWeak.addEventListener(event, () => contentToggler(btnWeak, contentWeak, btnStr, contentStr));
		}

		cards.forEach(card => {
			const btnStr = card.querySelector('.animals__str');
			const btnWeak = card.querySelector('.animals__weak');
			const contentStr = card.querySelector('.animals__simplebar--str');
			const contentWeak = card.querySelector('.animals__simplebar--weak');

			if (supportsTouch) {
				initHandler('touchstart', card, btnWeak, contentWeak, btnStr, contentStr);
			} else {
				initHandler('click', card, btnWeak, contentWeak, btnStr, contentStr);
			}
		});
	}
};