export const newsInit = () => {
	if (document.querySelector('.news__name')) {
		const names = document.querySelectorAll('.news__name');

		names.forEach(name => {
			const textArr = name.textContent.trim().split(' ')
			name.innerHTML = ''

			textArr.forEach(word => {
				const span = document.createElement('span');
				span.innerHTML = word + ' '
				name.appendChild(span)
			})
		})
	}
}
