export const uploadInputInit = () => {
    function uploadInput(input, label) {
        const upload = document.querySelector(input);
        const uploadLabel = document.querySelector(label);

        if (upload && uploadLabel) {
            upload.addEventListener("change", function () {
                let dots;
                const arr = this.files[0].name.split(".");
                arr[0].length > 6 ? (dots = "...") : (dots = ".");
                const name = arr[0].substring(0, 10) + dots + arr[1];
                uploadLabel.innerHTML = name;
            });
        }
    }

    uploadInput('.js-upload-input', '.js-upload-label');

}