import { aosInit } from './libs/aos.js';
import { choicesInit } from './libs/choices.js';
import { swiperInit } from './libs/swiper.js';
import { plyrInit } from './libs/plyr.js';
import { countUpInit } from './libs/countUp.lib.js';

import { vhInit } from './utils/vh.utils.js';

import { headerInit } from './components/header.component.js';
import { uploadInputInit } from './components/uploadInput.component.js';
import { newsInit } from './components/news.component.js';
import { animalsInit } from './components/animals.component.js';
import { noGapInit } from './components/noGap.component.js';
import { headerDropdownInit } from './components/headerDropdown.component.js';
import { smoothScrollInit } from './components/smoothScroll.component.js';
import LazyLoad from "vanilla-lazyload";

document.addEventListener("DOMContentLoaded", () => {
    var lazyLoadInstance = new LazyLoad({
        // Your custom settings go here
    });
    aosInit();
    choicesInit();
    swiperInit();
    plyrInit();
    countUpInit();
    vhInit();
    headerInit();
    uploadInputInit();
    newsInit();
    animalsInit();
    noGapInit();
    headerDropdownInit();
    smoothScrollInit();
});