import scrollLock from 'scroll-lock';

export const headerInit = () => {

const burgerMenu = (headerSelector, burgerSelector) => {
  const headerElem = document.querySelector(headerSelector),
      burgerElem = document.querySelector(burgerSelector);

  const removeClass = () => {
    headerElem.classList.remove("active");
    scrollLock.enablePageScroll();
  };
  removeClass();

  headerElem.querySelectorAll("a").forEach(link => {
    link.addEventListener('click', (e) => {
      if (
          headerElem.classList.contains('active') &&
          !link.classList.contains('open') &&
          !e.target.classList.contains('header__link-chevrone')
      ) {
        removeClass();
      }
    });
  });

  burgerElem.addEventListener("click", () => {
    if (headerElem.classList.contains("active")) {
      removeClass();
    } else {
      headerElem.classList.add("active");
      const method = 'margin';
      scrollLock.setFillGapMethod(method);
      scrollLock.disablePageScroll();

      if (document.querySelector(".header__simplebar") && innerWidth < 1025) {
        scrollLock.addScrollableSelector('.simplebar-content-wrapper');
      }

      scrollLock.addFillGapSelector('.header__navbar-box');
    }
  });


  window.addEventListener("resize", () => {
    innerWidth > 1024 && removeClass()
  });

  const stickyMenu = () => {
    window.scrollY >= 50 ? 
    headerElem.classList.add("sticky") :
    headerElem.classList.remove("sticky")
  }
  
  window.addEventListener("scroll", stickyMenu);
  stickyMenu();
};

if (document.querySelector(".header")) {
  burgerMenu(".header", ".header__burger-box");
}

}