import Plyr from 'plyr';
export const plyrInit = () => {
    if (document.querySelector('.js-video-player')) {
        const videoWraps = document.querySelectorAll(".js-video-player");

        videoWraps.forEach(video => {
            new Plyr(video, {
                controls: [
                    "play-large",
                    "play",
                    "progress",
                    "current-time",
                    "duration",
                    "mute",
                    "volume",
                    "captions",
                    "fullscreen",
                ],
            });
        });
    }
}
