import Swiper, {Autoplay, Navigation} from "swiper";
import {swiperOnResize} from "../utils/swiperOnResize.js";


export const swiperInit = () => {
if (document.querySelector(".benefit__slider")) {
    swiperOnResize('(max-width: 1023px)', '.benefit__slider',
        {
            modules: [Autoplay, Navigation],
            slidesPerView: 1,
            spaceBetween: 20,
            speed: 500,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            breakpoints: {
                568: {
                    slidesPerView: 2,
                },
                900: {
                    slidesPerView: 3,
                },
            },
            navigation: {
                prevEl: '.benefit .slider-btn.prev',
                nextEl: '.benefit .slider-btn.next',
            },
        },
    );
}

if (document.querySelector(".refer__slider")) {
    swiperOnResize('(max-width: 1023px)', '.refer__slider',
        {
            modules: [Autoplay, Navigation],
            slidesPerView: 1,
            spaceBetween: 20,
            speed: 500,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            breakpoints: {
                568: {
                    slidesPerView: 2,
                },
                900: {
                    slidesPerView: 3,
                },
            },
            navigation: {
                prevEl: '.refer .slider-btn.prev',
                nextEl: '.refer .slider-btn.next',
            },
        },
    );
}
if (document.querySelector(".team__slider")) {
    swiperOnResize('(max-width: 1023px)', '.team__slider',
        {
            modules: [Autoplay, Navigation],
            slidesPerView: 1,
            spaceBetween: 20,
            speed: 500,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            breakpoints: {
                375: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                },
            },
            navigation: {
                prevEl: '.team .slider-btn.prev',
                nextEl: '.team .slider-btn.next',
            },
        },
    );
}
if (document.querySelector(".footer__slider")) {
    swiperOnResize('(max-width: 1023px)', '.footer__slider',
        {
            modules: [Autoplay, Navigation],
            slidesPerView: 2,
            spaceBetween: 10,
            speed: 500,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            breakpoints: {
                658: {
                    slidesPerView: 3,
                },
                800: {
                    slidesPerView: 4,
                },
            },
            navigation: {
                prevEl: '.footer .slider-btn.prev',
                nextEl: '.footer .slider-btn.next',
            },
        },
    );
}

if (document.querySelector(".news__slider")) {
    new Swiper(".news__slider", {
        modules: [Autoplay, Navigation],
        slidesPerView: 1,
        spaceBetween: 20,
        speed: 500,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        breakpoints: {
            568: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            1024: {
                slidesPerView: 4,
            },
        },
        navigation: {
            prevEl: '.news .slider-btn.prev',
            nextEl: '.news .slider-btn.next',
        }
    });
}

if (document.querySelector(".awards__slider")) {
    new Swiper(".awards__slider", {
        modules: [Autoplay, Navigation],
        slidesPerView: 1,
        spaceBetween: 20,
        speed: 500,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        breakpoints: {
            640: {
                slidesPerView: 2,
            },
            769: {
                slidesPerView: 1,
            },
            1024: {
                slidesPerView: 2,
            },
        },
        navigation: {
            prevEl: '.awards .slider-btn.prev',
            nextEl: '.awards .slider-btn.next',
        }
    });
}

if (document.querySelector(".about__card-box")) {
    new Swiper(".about__card-box", {
        modules: [Autoplay, Navigation],
        slidesPerView: 1,
        spaceBetween: 20,
        speed: 500,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        breakpoints: {
            720: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 3,
            },
        },
        navigation: {
            prevEl: '.about .slider-btn.prev',
            nextEl: '.about .slider-btn.next',
        }
    });
}

function sliderHeight(sliderContainer) {
    let visibleSlides = sliderContainer.querySelectorAll('.swiper-slide-visible .positions__job');
    let fullHeight = 0;
    let maxHeight = 0;
    visibleSlides.forEach(slide => {
        if (slide.offsetHeight > maxHeight) {
            maxHeight = slide.offsetHeight;
        }
    })

    fullHeight = maxHeight * visibleSlides.length + 30;
    sliderContainer.style.height = fullHeight + 'px';
}

if (document.querySelector('.positions__slider')) {
    const positionSlider = document.querySelector('.positions__slider');
    let slides = positionSlider.querySelectorAll('.swiper-slide');
    const swiper = new Swiper(positionSlider, {
        modules: [Navigation, Autoplay],
        direction: 'vertical',
        slidesPerView: slides.length < 4 ? slides.length : 4,
        spaceBetween: 20,
        watchSlidesProgress: true,
        // speed: 500,
        // autoplay: {
        //     delay: 4000,
        //     disableOnInteraction: false,
        //     pauseOnMouseEnter: true,
        // },
        navigation: {
            prevEl: ".positions .slider-btn.prev",
            nextEl: ".positions .slider-btn.next",
        },
        breakpoints: {
            769: {
                spaceBetween: 30,
            },
        },
        on: {
            init: () => {
                sliderHeight(positionSlider);
            },
            resize: () => {
                sliderHeight(positionSlider);
            },
            slideChange: () => {
                sliderHeight(positionSlider);
            }
        }


    });
}
if (document.querySelector(".values__slider")) {
    new Swiper(".values__slider", {
        modules: [Autoplay, Navigation],
        slidesPerView: 1,
        spaceBetween: 20,
        speed: 500,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        breakpoints: {
            568: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            1024: {
                slidesPerView: 4,
            },
        },
        navigation: {
            prevEl: '.values .slider-btn.prev',
            nextEl: '.values .slider-btn.next',
        }
    });
}

if (document.querySelector(".testimonials__slider")) {
    new Swiper(".testimonials__slider", {
        modules: [Autoplay, Navigation],
        slidesPerView: 1,
        spaceBetween: 15,
        speed: 500,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        breakpoints: {
            568: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 3,
            },
        },
        navigation: {
            prevEl: '.testimonials .slider-btn.prev',
            nextEl: '.testimonials .slider-btn.next',
        }
    });
}
if (document.querySelector(".resources__slider")) {
    new Swiper(".resources__slider", {
        modules: [Autoplay, Navigation],
        slidesPerView: 1,
        spaceBetween: 20,
        speed: 500,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        breakpoints: {
            568: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 3,
            },
        },
        navigation: {
            prevEl: '.resources .slider-btn.prev',
            nextEl: '.resources .slider-btn.next',
        }
    });
}
}